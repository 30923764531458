import React, { useState, useEffect } from "react";
import { TextField, FormControl, InputLabel, Select, Dialog, DialogActions, DialogContent, DialogTitle, Button, Card, CardContent, MenuItem } from "@mui/material";
import { useParams } from "react-router-dom";
import axios from "axios";
import BattleExercise from "./BattleExercise";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useLocalStorage } from "@uidotdev/usehooks";
import { tab } from "@testing-library/user-event/dist/tab";

const Battle = () => {
  const { skillId, stageId } = useParams();
  const [battles, setBattles] = useState([]);
  const [isAddDialogOpen, setAddDialogOpen] = useState(false);
  const [formData, setFormData] = useState({ battleId: "", battleName: "" });
  const [insertedQuestionIds, setInsertedQuestionIds] = useState([]);
  const [isReplicateDialogOpen, setReplicateDialogOpen] = useState(false);
  const [currentBattleId, setCurrentBattleId] = useState(null);
  const [missionInputValue, setMissionInputValue] = useState([]);
  const [missionNumValue,setMissionNumValue]=useState([])
  const [missions,setMissions]=useState([]);
  const [filteredMissions,setFilteredMissions]=useState([])
  const [replicateFormData, setReplicateFormData] = useState({
    newSkillId: "",
    newStageId: "",
    newBattleName: "",
  });
  const userTabIds = JSON.parse(localStorage.getItem("tab_id")) || [];
  const isAuthorized = userTabIds.includes(4);
  const [skills, setSkills] = useState([]);
  const [stages, setStages] = useState([]);
  const [selectedSkill, setSelectedSkill] = useState(null);
  const [selectedStage, setSelectedStage] = useState(null);
  const [token,setToken] = useLocalStorage('token',null);

  useEffect(() => {
    fetchBattles();
  }, [skillId, stageId]);

  useEffect(()=>{
    fetchMissions()
  },[])

  // useEffect(()=>{
  //   filterMission()
  // },[missionInputValue])

  // const filterMission=()=>{
  //   const temp=missions.filter((mission)=>{
  //     return !missionInputValue.includes(mission.mission_id)
  //   })
  //   setFilteredMissions(temp)
  // }
  const fetchSkills = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/skills/all`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSkills(response.data.data || []);
    } catch (error) {
      console.error("Error fetching skills:", error);
      setSkills([]);
    }
  };

  const fetchStages = async (skillId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/stages/all/${skillId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const fetchedStages = response.data.data.stages;
      console.log("Fetched Stages:", fetchedStages);
      setStages(Array.isArray(fetchedStages) ? fetchedStages : []);
    } catch (error) {
      console.error("Error fetching stages:", error);
      setStages([]);
    }
  };

  useEffect(() => {
    fetchSkills();
  }, []);

  useEffect(() => {
    if (selectedSkill) {
      console.log("Selected Skill ID:", selectedSkill.skill_id);
      fetchStages(selectedSkill.skill_id);
    }
  }, [selectedSkill]);

  const handleSkillSelectChange = (event) => {
    const selectedSkillId = event.target.value;
    const selectedSkillObj = skills.find(
      (skill) => skill.skill_id === selectedSkillId
    );
    setSelectedSkill(selectedSkillObj);
    setReplicateFormData((prevData) => ({
      ...prevData,
      newSkillId: selectedSkillId,
      newStageId: "",
    }));
    setSelectedStage(null);
    fetchStages(selectedSkillId);    
  };

  const handleStageSelectChange = (event) => {
    const selectedStageId = event.target.value;
    const selectedStageObj = stages.find(
      (stage) => stage.stage_id === selectedStageId
    );
    setSelectedStage(selectedStageObj);
    setReplicateFormData((prevData) => ({
      ...prevData,
      newStageId: selectedStageId,
    }));
  };

  const fetchMissions=async()=>{
    try {
      const response=await axios.get(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/missions/all/${stageId}`,{
        headers:{
          Authorization:`Bearer ${token}`
        }
      })

      if (response.data.data.length===0) {
        toast.error("No missions Found for the current stage")
      }

      setMissions(response.data.data)
      // console.log(response.data.data);
      
    } catch (error) {
      console.error("Error in fetchMissions",error);
      
    }
  }

  const fetchBattles = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/battle/all/${stageId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        setBattles(response.data.data);
      } else {
        console.error("Error fetching battles:", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching battles:", error);
    }
  };

  const inputFields = [
    { label: "Battle ID", name: "battle_id", type: "number", disabled: true },
    { label: "Battle Name", name: "battle_name" },
    { label: "Battle Order", name: "battle_order", type: "number" },
    // { label: "Question Type", name: "questionType" },
    { label: "Marks/Question", name: "marks_per_question", type: "number" },
    { label: "Negative Marks", name: "negative_marks", type: "number" },
    { label: "No. of Questions", name: "no_of_questions", type: "number" },
    { label: "Description", name: "description", type: "multiline" },
    { label: "Instructions", name: "instructions", type: "multiline" },
    { label: "Max Time", name: "max_time", type: "time" },
    { label: "Clear Score", name: "clear_score", type: "number" },
    { label: "Coin Type", name: "coin_type" },
    { label: "Specialization ID", name: "specialization_id", type: "number" },
  ];

  const handleAdd = () => {
    setFormData({ battleId: "", battleName: "" });
    setAddDialogOpen(true);
  };

  const handleEdit = (battleId) => {
    const battleToEdit = battles.find(
      (battle) => battle.battle_id === battleId
    );
    console.log(battleToEdit);
    const missionData=JSON.parse(battleToEdit.mission_question_data)
    const missionIds=missionData.map(val=>val.mission_id)
    const numOfQuestion=missionData.map(val=>val.num_of_questions)
    setFormData({
      battle_id: battleToEdit.battle_id,
      battle_name: battleToEdit.battle_name,
      battle_order: battleToEdit.battle_order,
      questionType: battleToEdit.question_type || "lorem",
      marks_per_question: battleToEdit.marks_per_question,
      negative_marks: battleToEdit.negative_marks || 0,
      no_of_questions: battleToEdit.no_of_questions,
      description: battleToEdit.description,
      instructions: battleToEdit.instructions,
      max_time: battleToEdit.max_time,
      coin_type: battleToEdit.coin_type,
      clear_score: battleToEdit.clear_score,
      specialization_id: battleToEdit.specialization_id,
      // mission_ids:missionIds,
      // mission_no_of_questions:numOfQuestion
    });
    setMissionInputValue(missionIds);
    setMissionNumValue(numOfQuestion)
    setAddDialogOpen(true);
  };

  const handleOpenReplicateDialog = (battleId) => {
    setReplicateFormData({
      newSkillId: "",
      newStageId: "",
      newBattleName: "",
    });

    setReplicateDialogOpen(true);
  };

  const handleReplicate = (battleId) => {
    setCurrentBattleId(battleId);
    handleOpenReplicateDialog(battleId);
  };

  const handleReplicateInputChange = (event) => {
    setReplicateFormData({
      ...replicateFormData,
      [event.target.name]: event.target.value,
    });
  };

  const handleReplicateSubmit = async () => {
    const oldStageId = stageId;
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/replicate/battle/${currentBattleId}`,
        {
          ...replicateFormData,
          oldStageId: oldStageId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        toast.success("Battle replicated successfully");
        setReplicateDialogOpen(false);
        fetchBattles();
      } else {
        toast.error(`${response.data.message}`);
      }
    } catch (error) {
      toast.error(`${error.response.data.message}`);
    }
  };

  const handleDelete = async (battleId) => {
    try {
      confirmAlert({
        title: "Confirm Deletion",
        message: "Are you sure you want to delete this battle?",
        buttons: [
          {
            label: "Yes",
            style: {
              background: "#d32f2f",
              color: "#ffffff",
            },
            onClick: async () => {
              try {
                const apiUrl = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/battle/delete/${battleId}`;
                const config = {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                };
                const response = await axios.delete(apiUrl, config);

                if (response.status === 200) {
                  toast.success("Battle deleted successfully");
                  fetchBattles();
                } else {
                  console.error("Error deleting battle:", response.data);
                  toast.error(
                    `Error deleting battle: ${response.data.message}`
                  );
                }
              } catch (error) {
                console.error("Error deleting battle: ", error);
                toast.error(`Error deleting battle: ${error.message}`);
              }
            },
          },
          {
            label: "No",
            style: {
              background: "#1976d2",
              color: "#ffffff",
            },
            onClick: () => {},
          },
        ],
      });
    } catch (error) {
      console.error("Error deleting battle: ", error);
      toast.error(`Error deleting battle: ${error.message}`);
    }
  };
  const handleCloseDialog = () => {
    setInsertedQuestionIds([]);
    setMissionInputValue([])
    setMissionNumValue([])
    setAddDialogOpen(false);
  };

  const handleSave = async () => {
    try {
      if (!isTimeValid(formData.max_time)) {
        toast.error('Invalid time format for Speed. Please use "hh:mm:ss".');
        return;
      }
      const apiUrl = formData.battle_id
        ? `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/battle/update/${formData.battle_id}`
        : `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/battle/add`;

      const payload = {
        battle_name: formData.battle_name,
        battle_order: formData.battle_order,
        questionType: formData.questionType || "lorem",
        marks_per_question: formData.marks_per_question,
        negative_marks: formData.negative_marks || 0,
        no_of_questions: formData.no_of_questions,
        description: formData.description,
        instructions: formData.instructions,
        max_time: formData.max_time,
        clear_score: formData.clear_score,
        coin_type: formData.coin_type,
        questionIds: insertedQuestionIds,
        specialization_id: formData.specialization_id,
        skill_id: skillId,
        stageId: stageId,
        mission_list:missionInputValue,
        mission_qnum:missionNumValue
      };

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios[formData.battle_id ? "put" : "post"](
        apiUrl,
        payload,
        config
      );

      if (response.status === 201 || response.status === 200) {
        toast.success("Battle saved successfully");
        fetchBattles();
      } else {
        toast.error("Error saving battle:", response.data);
      }

      handleCloseDialog();
    } catch (error) {
      console.log(error.response.data.message);
      
      toast.error(`Error saving battle: ${error.response.data.message}`);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const isTimeValid = (time) => {
    const timeRegex = /^([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$/;
    return timeRegex.test(time);
  };

  if (!isAuthorized) {
    return <div className="container-fluid mt-5"></div>;
  }

  const hangleMissionChange=(e,index)=>{
    console.log(e.target.value);
    
    setMissionInputValue(prev=>{
      const temp=[...prev];
      temp[index]=e.target.value;
      return temp;
    })
    console.log(missionInputValue);
    
  }

  const onAddClick=()=>{
    setMissionInputValue(prev=>{
      return [...prev,-1]
    })
    setMissionNumValue(prev=>{
      return [...prev,0]
    })
  }
  // console.log();
  
  const onRemoveClick=(index)=>{
    if (missionInputValue.length===1) {
      setMissionInputValue([])
      setMissionNumValue([])
      return
    }
    let temp=missionInputValue
    let temp2=missionNumValue
    temp=temp.filter((val,ind)=>{
      // console.log(ind,index);
      
      return ind!==index;
    })
    temp2=temp2.filter((val,ind)=>{
      // console.log(ind,index);
      
      return ind!==index;
    })
    setMissionInputValue(temp)
    setMissionNumValue(temp2)
    
  }
  console.log(missionInputValue);

  const handleMissionNumberInputChange=(e,index)=>{
    const temp=missionNumValue
    temp[index]=Number(e.target.value);
    console.log(temp);
    setMissionNumValue(prev=>{
      const temp=[...prev];
      temp[index]=Number(e.target.value);
      return temp;
    });
    
  }

  console.log(missionInputValue);
  console.log(missionNumValue);
  console.log(missionInputValue[0]);
  console.log(missionNumValue[0]);
  
  
  
  
  return (
    <div className="container-fluid mt-5">
      <div className="align-center-all full-height">
        <Card elevation={3} className="col-md-12 p-3">
          <CardContent>
            <h2>Battle Page</h2>
            <Button variant="contained" color="primary" onClick={handleAdd}>
              Add
            </Button>
            <table className="table">
              <thead>
                <tr>
                  <th>Battle ID</th>
                  <th>Name</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {battles.map((battle) => (
                  <tr key={battle.battle_id}>
                    <td>{battle.battle_id}</td>
                    <td>{battle.battle_name}</td>
                    <td>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleEdit(battle.battle_id)}
                      >
                        Edit
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleReplicate(battle.battle_id)}
                      >
                        Replicate
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => handleDelete(battle.battle_id)}
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </CardContent>
        </Card>

        <Dialog open={isAddDialogOpen} onClose={handleCloseDialog}>
          <DialogTitle>
            {formData.battle_id ? "Update Battle" : "Add Battle"}
          </DialogTitle>
          <DialogContent>
            {/* <BattleExercise
              onQuestionsAdded={(ids) => setInsertedQuestionIds(ids)}
              questionType={formData.questionType}
              battleId={formData.battle_id}
            /> */}
            {inputFields.map((field) => (
              <TextField
                key={field.name}
                label={field.label}
                name={field.name}
                value={formData[field.name] || ""}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
                type={"text"}
                disabled={field.disabled}
                error={
                  field.name === "max_time" && !isTimeValid(formData.max_time)
                }
                helperText={
                  field.name === "max_time" && !isTimeValid(formData.max_time)
                    ? 'Invalid time format. Please use "hh:mm:ss".'
                    : ""
                }
              />
            ))}

            {missionInputValue.map((val,index) => {
              return (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "1rem",
                    marginBottom:"1rem"
                  }}
                  key={index}
                >
                  <TextField 
                  select
                  // value={val}
                  value={missionInputValue[index]}
                  fullWidth
                  onChange={(e,val)=>{hangleMissionChange(e,index)}}
                  >
                    <MenuItem key={-1} value={-1} ></MenuItem>
                    {
                      missions.map((val,ind)=>{
                        return(
                          <MenuItem key={ind} value={val.mission_id} >{val.mission_name}</MenuItem>
                        )
                      })
                    }
                  </TextField>
                  <TextField 
                  type="number"
                    style={{width:"30%"}}
                    value={missionNumValue[index]}
                    onChange={(e)=>{handleMissionNumberInputChange(e,index)}}
                  />
                  <Button onClick={()=>{onRemoveClick(index)}} variant="contained" color="error" >Remove</Button>
                </div>
              );
            })}
            <Button variant="contained" color="primary" onClick={onAddClick} >
              Add
            </Button>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSave} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={isReplicateDialogOpen}
          onClose={() => setReplicateDialogOpen(false)}
        >
          <DialogTitle>Replicate Battle</DialogTitle>
          <DialogContent>
            {/* <TextField
              label="New Skill ID"
              name="newSkillId"
              value={replicateFormData.newSkillId}
              onChange={handleReplicateInputChange}
              fullWidth
              margin="normal"
              type="number"
            />
            <TextField
              label="New Stage ID"
              name="newStageId"
              value={replicateFormData.newStageId}
              onChange={handleReplicateInputChange}
              fullWidth
              margin="normal"
              type="number"
            /> */}
                        <FormControl fullWidth margin="normal">
              <InputLabel id="skill-select-label">Skill</InputLabel>
              <Select
                labelId="skill-select-label"
                value={replicateFormData.newSkillId}
                onChange={handleSkillSelectChange}
                label="Skill"
              >
                {skills.map((skill) => (
                  <MenuItem key={skill.skill_id} value={skill.skill_id}>
                    {skill.skill_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel id="stage-select-label">Stage</InputLabel>
              <Select
                labelId="stage-select-label"
                value={replicateFormData.newStageId}
                onChange={handleStageSelectChange}
                label="Stage"
              >
                {stages.map((stage) => (
                  <MenuItem key={stage.stage_id} value={stage.stage_id}>
                    {stage.stage_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              label="New Battle Name"
              name="newBattleName"
              value={replicateFormData.newBattleName}
              onChange={handleReplicateInputChange}
              fullWidth
              margin="normal"
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setReplicateDialogOpen(false)}
              color="primary"
            >
              Cancel
            </Button>
            <Button onClick={() => handleReplicateSubmit()} color="primary">
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

export default Battle;
