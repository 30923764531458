import React, { useState, useEffect } from "react";
import WarReportMobile from "./WarReportMobile";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import axios from "axios";
import "./WarReport.css";
import { useLocalStorage } from "@uidotdev/usehooks";
import isDesktop from "../isDesktop";

const WarReport = () => {
  const isDesktopDevice = isDesktop();
  const [skills, setSkills] = useState([]);
  const [selectedSkill, setSelectedSkill] = useState("");
  const [token,setToken] = useLocalStorage('token',null);
  const [filter, setFilter] = useState("");
  const [wars, setWars] = useState([]);
  const [selectedWar, setSelectedWar] = useState("");
  const [reports, setReports] = useState([]);
  const userTabIds = JSON.parse(localStorage.getItem("tab_id")) || [];
  const isAuthorized = userTabIds.includes(11);

  const handleInputChange = (e) => {
    setFilter(e.target.value);
  };

  const fetchSkills = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/skills/all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        setSkills(response.data.data);
      } else {
        console.error("Error fetching skills: ", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching skills: ", error);
    }
  };

  const fetchWars = async (skillId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/war/all/${skillId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        setWars(response.data.data);
        console.log(response.data.data, " got the wars");
      } else {
        console.error("Error fetching wars:", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching wars:", error);
    }
  };

  const fetchReports = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/report/war-report`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        setReports(response.data.data);
      } else {
        console.error("Error fetching reports:", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching reports:", error);
    }
  };

  const handleSkillChange = (event) => {
    const selectedSkillId = event.target.value;
    setSelectedSkill(selectedSkillId);
    fetchWars(selectedSkillId);
  };

  const handleExportClick = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/report/export-war`,
        {
          name: filter,
          war_id: selectedWar,
          skill_id: selectedSkill,
        },
        {
          responseType: "blob",
        }
      );
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "WarAttempts.xlsx");
      document.body.appendChild(link);
      link.click();
      console.log("File exported successfully");
    } catch (error) {
      console.error("Error exporting file:", error);
    }
  };

  useEffect(() => {
    if(isDesktopDevice) {
      fetchSkills();
      // fetchReports();
    }
  }, []);

  const handleReset = () => {
    setFilter("");
    setSelectedSkill("");
    setSelectedWar("");
  };

  if(!isAuthorized) {
    return (
      <div className="container-fluid mt-5">
        <h2>You are unauthorized to access this page</h2>
      </div>
    )
  }

  function formatTime(dateTimeString) {
    const date = new Date(dateTimeString);
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
  
    const amPm = hours >= 12 ? 'PM' : 'AM';
  
    hours = hours % 12;
    hours = hours ? hours : 12;
  
    const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${amPm}`;
  
    return formattedTime;
  }  
  return (
    <>
    {
      isDesktopDevice ? (
        <div className="container-fluid mt-3 p-0">
      <div className="align-center-all full-height">
          <CardContent style={{padding: '0'}}>
            <div className="filters">
            <div className="sub-title">War Report</div>
            <div
              style=
              {{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              <div className="fieldContainer">
                <div className="leftFields">
                <FormControl
                  fullWidth
                  className="mb-3 customTextfield"
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: 'transparent',
                      },
                      '&:hover fieldset': {
                        borderColor: 'transparent',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'transparent',
                        boxShadow: 'none',
                      },
                    },
                    width: '32.051vw'
                  }}
                >
                  <InputLabel id="skill-label">Skill</InputLabel>
                    <Select
                      labelId="skill-label"
                      id="skill-select"
                      label="Skill"
                      value={selectedSkill}
                      onChange={handleSkillChange}
                    >
                      <MenuItem value="">None</MenuItem>
                      {skills.map((skill) => (
                        <MenuItem key={skill.skill_id} value={skill.skill_id}>
                          {skill.skill_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl
                  fullWidth
                  className="mb-3 customTextfield"
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: 'transparent',
                      },
                      '&:hover fieldset': {
                        borderColor: 'transparent',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'transparent',
                        boxShadow: 'none',
                      },
                    },
                    width: '32.051vw'
                  }}
                >
                  <InputLabel id="war-label">War</InputLabel>
                    <Select
                      labelId="war-label"
                      id="war-select"
                      label="War"
                      value={selectedWar}
                      onChange={(e) => {
                        setSelectedWar(e.target.value);
                      }}
                    >
                      <MenuItem value="">None</MenuItem>
                      {wars.map((war) => (
                        <MenuItem key={war.war_id} value={war.war_id}>
                          {war.war_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="rightFields">
                  {/* <TextField
                    label="Name"
                    variant="outlined"
                    value={filter}
                    fullWidth
                    onChange={handleInputChange}
                    placeholder="Enter Name"
                    className="mb-3 customTextfield"
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'transparent',
                        },
                        '&:hover fieldset': {
                          borderColor: 'transparent',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'transparent',
                          boxShadow: 'none',
                        },
                      },
                      width: '32.051vw'
                    }}
                  /> */}
                  <TextField
                    label="Name"
                    variant="outlined"
                    value={filter}
                    fullWidth
                    onChange={handleInputChange}
                    placeholder="Enter Name"
                    className="mb-3 customTextfield"
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'transparent',
                        },
                        '&:hover fieldset': {
                          borderColor: 'transparent',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'transparent',
                          boxShadow: 'none',
                        },
                      },
                      width: '32.051vw'
                    }}
                  />
                </div>
              </div>
                  <div>
                    <Button
                      variant="outlined"
                      className="mr-1 outlined-button"
                      onClick={handleReset}
                    >
                      Reset
                    </Button>
                    <Button
                      variant="contained"
                      // color="primary"
                      onClick={handleExportClick}
                      className="filled-button"
                      style={{
                        height: '2.564vw',
                        width: '15.385vw',
                        borderRadius: '4px',
                        textTransform: 'capitalize',
                        color: '#fff',
                        backgroundColor: '#37446F'
                      }}
                    >
                      Export
                    </Button>
                  </div>
                </div>
                <table className="table mt-5">
                  <thead className="table-head">
                    <tr>
                      <th>ID</th>
                      <th>Name</th>
                      <th>Class</th>
                      <th>Earned/Total Score</th>
                      <th>Start Time</th>
                      <th>End Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {reports
                      ?.filter(
                        (report) =>
                          (!filter ||
                            report.name
                              ?.toLowerCase()
                              .includes(filter?.toLowerCase()) ||
                            filter === "") &&
                          (!selectedWar ||
                            report.war_id?.toString().includes(selectedWar) ||
                            selectedWar === "") &&
                          (!selectedSkill ||
                            report.skill_id
                              ?.toString()
                              .includes(selectedSkill) ||
                            selectedSkill === "")
                      )
                      .map((filteredReport) => (
                        <tr key={filteredReport.attempt_id}>
                          <td>{filteredReport.attempt_id}</td>
                          <td>{filteredReport.name}</td>
                          <td>{filteredReport.user_class}</td>
                          <td>{`${filteredReport.total_correct}/${filteredReport.total_questions}`}</td>
                          <td>{formatTime(filteredReport.start_time)}</td>
                          <td>{formatTime(filteredReport.end_time)}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
            </div>
          </CardContent>
      </div>
    </div>
      ) : (
        <WarReportMobile />
      )
    }
    </>
  );
};

export default WarReport;
