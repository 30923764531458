import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import { useParams } from "react-router-dom";
import axios from "axios";
import Battle from "../battle/Battle";
import Question from "../question/Question";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useLocalStorage } from "@uidotdev/usehooks";
import {
  Autocomplete,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
} from "@mui/material";
import service from "../stage/stageService";
import Switch from "@mui/material/Switch";

const Mission = () => {
  const { skillId, stageId } = useParams();
  const [missions, setMissions] = useState([]);
  const [isAddDialogOpen, setAddDialogOpen] = useState(false);
  const [isReplicateDialogOpen, setReplicateDialogOpen] = useState(false);
  const [currentMissionId, setCurrentMissionId] = useState(null);
  const [replicateFormData, setReplicateFormData] = useState({
    newSkillId: "",
    newStageId: "",
    newMissionName: "",
  });
  const [formData, setFormData] = useState({ missionId: "", name: "" });
  const userTabIds = JSON.parse(localStorage.getItem("tab_id")) || [];
  const isAuthorized = userTabIds.includes(4);
  const [insertedQuestionIds, setInsertedQuestionIds] = useState([]);
  const [skills, setSkills] = useState([]);
  const [stages, setStages] = useState([]);
  const [selectedSkill, setSelectedSkill] = useState(null);
  const [selectedStage, setSelectedStage] = useState(null);

  const [token,setToken] = useLocalStorage('token',null);
  const [visibility, setVisibility] = useState(formData.visibility === '1');

  const handleVisibilityChange = (event) => {
    setVisibility(event.target.checked);
    setFormData((prevData) => ({
      ...prevData,
      visibility: event.target.checked ? '1' : '0',
    }));
  };

  const fetchSkills = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/skills/all`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSkills(response.data.data || []);
    } catch (error) {
      console.error("Error fetching skills:", error);
      setSkills([]);
    }
  };

  const fetchStages = async (skillId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/stages/all/${skillId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const fetchedStages = response.data.data.stages;
      console.log("Fetched Stages:", fetchedStages);
      setStages(Array.isArray(fetchedStages) ? fetchedStages : []);
    } catch (error) {
      console.error("Error fetching stages:", error);
      setStages([]);
    }
  };  


  useEffect(() => {
    fetchSkills();
  }, []);

  useEffect(() => {
    if (selectedSkill) {
      console.log("Selected Skill ID:", selectedSkill.skill_id);
      fetchStages(selectedSkill.skill_id);
    }
  }, [selectedSkill]);
  
  const inputFields = [
    { label: "Mission ID", name: "missionId", type: "number", disabled: true },
    { label: "Mission Name", name: "name" },
    { label: "Video Link", name: "videoLink" },
    { label: "Mission Order", name: "missionOrder", type: "number" },
    // { label: "Question Type", name: "questionType" },
    { label: "Instructions", name: "instructions", type: "multiline" },
    { label: "Display Columns", name: "displayColumns", type: "number" },
    { label: "Speed", name: "speed", type: "time" },
    { label: "Accuracy %", name: "accuracy", type: "number" },
    { label: "Max Questions", name: "maxQuestions", type: "number" },
    // { label: "Visibility", name: "visibility", type: "number" },
    { label: "Coin Type", name: "coinType", type: "number" },
    { label: "Specialization ID", name: "specializationId", type: "number" },
  ];

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/missions/all/${stageId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.data) {
        setMissions(response.data.data);
      } else {
        console.error("Empty mission data received");
      }
    } catch (error) {
      console.error("Error fetching mission data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [token]);

  const handleAdd = () => {
    setFormData({ missionId: "", name: "" });
    setAddDialogOpen(true);
  };

  const handleEdit = (missionId) => {
    const missionToEdit = missions.find(
      (mission) => mission.mission_id === missionId
    );

    setFormData({
      missionId: missionToEdit.mission_id,
      name: missionToEdit.mission_name,
      videoLink: missionToEdit.video_link,
      missionOrder: missionToEdit.mission_order,
      questionType: missionToEdit.question_type|| "lorem",
      instructions: missionToEdit.instructions,
      displayColumns: missionToEdit.display_columns,
      speed: missionToEdit.speed,
      accuracy: missionToEdit.accuracy,
      maxQuestions: missionToEdit.max_questions,
      visibility: missionToEdit.visibility,
      coinType: missionToEdit.coin_type,
      specializationId: missionToEdit.specialization_id,
    });

    setAddDialogOpen(true);
  };

  const handleReplicate = (missionId) => {
    setCurrentMissionId(missionId);
    setReplicateDialogOpen(true);
    setReplicateFormData({
      newSkillId: "",
      newStageId: "",
      newMissionName: "",
    });
    setSelectedSkill(null);
    setSelectedStage(null);
    setStages([]);
  };

  const handleReplicateDialogClose = () => {
    setReplicateDialogOpen(false);
    setCurrentMissionId("");
    setReplicateFormData({
      newSkillId: "",
      newStageId: "",
      newMissionName: "",
    });
    setSelectedSkill(null);
    setSelectedStage(null);
    setStages([]);
  };

  const handleSkillSelectChange = (event) => {
    const selectedSkillId = event.target.value;
    const selectedSkillObj = skills.find(
      (skill) => skill.skill_id === selectedSkillId
    );
    setSelectedSkill(selectedSkillObj);
    setReplicateFormData((prevData) => ({
      ...prevData,
      newSkillId: selectedSkillId,
      newStageId: "",
    }));
    setSelectedStage(null);
    fetchStages(selectedSkillId);    
  };

  const handleStageSelectChange = (event) => {
    const selectedStageId = event.target.value;
    const selectedStageObj = stages.find(
      (stage) => stage.stage_id === selectedStageId
    );
    setSelectedStage(selectedStageObj);
    setReplicateFormData((prevData) => ({
      ...prevData,
      newStageId: selectedStageId,
    }));
  };

  const handleReplicateSubmit = async () => {
    try {
      if (
        !replicateFormData.newSkillId ||
        !replicateFormData.newStageId ||
        !replicateFormData.newMissionName
      ) {
        toast.error("Please fill in all fields.");
        return;
      }
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/replicate/mission/${currentMissionId}`,
        {
          newSkillId: replicateFormData.newSkillId,
          oldStageId: stageId,
          newStageId: replicateFormData.newStageId,
          newMissionName: replicateFormData.newMissionName,
          oldMissionId: currentMissionId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        toast.success(`${response.data.message}`);
        fetchData();
        setReplicateDialogOpen(false);
      } else if (response.status === 400) {
        toast.error(`${response.data.message}`);
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(`${error.response.data.message}`);
      } else {
        toast.error("An error occurred during replication.");
      }
    }
  };

  const handleDelete = async (missionId) => {
    try {
      confirmAlert({
        title: "Confirm Deletion",
        message: "Are you sure you want to delete this mission?",
        buttons: [
          {
            label: "Yes",
            style: {
              background: "#d32f2f",
              color: "#ffffff",
            },
            onClick: async () => {
              try {
                const apiUrl = `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/missions/delete/${missionId}`;
                const config = {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                };
                const response = await axios.delete(apiUrl, config);

                if (response.status === 200) {
                  console.log(`Mission ${missionId} deleted successfully`);
                  const updatedMissions = missions.filter(
                    (mission) => mission.mission_id !== missionId
                  );
                  setMissions(updatedMissions);
                } else {
                  console.error(
                    `Error deleting Mission ${missionId}`,
                    response.data
                  );
                }
              } catch (error) {
                console.error(`Error deleting Mission ${missionId}`, error);
              }
            },
          },
          {
            label: "No",
            style: {
              background: "#1976d2",
              color: "#ffffff",
            },
            onClick: () => {},
          },
        ],
      });
    } catch (error) {
      console.error(`Error deleting Mission ${missionId}`, error);
    }
  };

  const handleCloseDialog = () => {
    setInsertedQuestionIds([]);
    setAddDialogOpen(false);
    setReplicateDialogOpen(false);
    setCurrentMissionId(null);
  };

  const handleSave = async (skillId, stageId) => {
    try {
      if (!isTimeValid(formData.speed)) {
        toast.error('Invalid time format for Speed. Please use "hh:mm:ss".');
        return;
      }
      if (formData.missionId) {
        const response = await axios.put(
          `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/missions/update/${formData.missionId}`,
          {
            missionName: formData.name,
            videoLink: formData.videoLink,
            missionOrder: formData.missionOrder,
            questionType: formData.questionType||"lorem",
            instructions: formData.instructions,
            displayColumns: formData.displayColumns,
            speed: formData.speed,
            accuracy: formData.accuracy,
            maxQuestions: formData.maxQuestions,
            visibility: formData.visibility,
            questionIds: insertedQuestionIds,
            coinType: formData.coinType,
            specializationId: formData.specializationId,
            skillId: skillId,
            stageId: stageId,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          toast.success(`${response.data.message}`);
          const updatedMissions = missions.map((mission) =>
            mission.mission_id === formData.missionId
              ? { ...mission, ...formData }
              : mission
          );
          setMissions(updatedMissions);
          fetchData();
        } else {
          toast.error(`${response.data.message}`);
        }
      } else {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_KEY}/missions/add`,
          {
            missionName: formData.name,
            videoLink: formData.videoLink,
            missionOrder: formData.missionOrder,
            questionType: formData.questionType || "Lorem",
            instructions: formData.instructions,
            displayColumns: formData.displayColumns,
            speed: formData.speed,
            accuracy: formData.accuracy,
            maxQuestions: formData.maxQuestions,
            questionIds: insertedQuestionIds,
            visibility: formData.visibility,
            coinType: formData.coinType,
            specializationId: formData.specializationId,
            skillId: skillId,
            stageId: stageId,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          toast.success(`${response.data.message}`);
          fetchData();
        } else {
          toast.error(`${response.data.message}`);
        }
      }

      handleCloseDialog();
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(`${error.response.data.message}`);
      } else {
        toast.error("An error occurred while saving the mission.");
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const isTimeValid = (time) => {
    const timeRegex = /^([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$/;
    return timeRegex.test(time);
  };

  const handleQuestionsAdded = (ids) => {
    setInsertedQuestionIds(ids);
  };

  return (
    <div className="container-fluid mt-5">
      <div className="align-center-all full-height">
        {isAuthorized ? (
          <Card elevation={3} className="col-md-12 p-3">
            <CardContent>
              <h2>Mission Page</h2>
              <ToastContainer />
              <Button variant="contained" color="primary" onClick={handleAdd} className="button-two">
                Add
              </Button>
              <table className="table">
                <thead>
                  <tr>
                    <th>Mission ID</th>
                    <th>Name</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {missions.map((mission) => (
                    <tr key={mission?.mission_id}>
                      <td>{mission?.mission_id || ""}</td>
                      <td>{mission?.mission_name || ""}</td>
                      <td>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleEdit(mission.mission_id)}
                          className="button-two"
                        >
                          Edit
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleReplicate(mission.mission_id)}
                          className="button-two"
                        >
                          Replicate
                        </Button>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => handleDelete(mission.mission_id)}
                          className="button-two"
                        >
                          Delete
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </CardContent>
          </Card>
        ) : (
          <h2>You are unauthorized to access this page.</h2>
        )}

        {/* Replication Dialog */}
        <Dialog
          open={isReplicateDialogOpen}
          onClose={handleReplicateDialogClose}
        >
          <DialogTitle>Replicate Mission</DialogTitle>
          <DialogContent>
            <FormControl fullWidth margin="normal">
              <InputLabel id="skill-select-label">Skill</InputLabel>
              <Select
                labelId="skill-select-label"
                value={replicateFormData.newSkillId}
                onChange={handleSkillSelectChange}
                label="Skill"
              >
                {skills.map((skill) => (
                  <MenuItem key={skill.skill_id} value={skill.skill_id}>
                    {skill.skill_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel id="stage-select-label">Stage</InputLabel>
              <Select
                labelId="stage-select-label"
                value={replicateFormData.newStageId}
                onChange={handleStageSelectChange}
                label="Stage"
              >
                {stages.map((stage) => (
                  <MenuItem key={stage.stage_id} value={stage.stage_id}>
                    {stage.stage_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              label="New Mission Name"
              name="newMissionName"
              value={replicateFormData.newMissionName}
              onChange={(e) =>
                setReplicateFormData({
                  ...replicateFormData,
                  newMissionName: e.target.value,
                })
              }
              fullWidth
              margin="normal"
              type="text"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleReplicateDialogClose} color="error" variant="contained">
              Cancel
            </Button>
            <Button onClick={handleReplicateSubmit} color="primary" variant="contained">
              Replicate
            </Button>
          </DialogActions>
        </Dialog>

        {/* Add/Edit Mission Dialog */}
        <Dialog open={isAddDialogOpen} onClose={handleCloseDialog}>
          <DialogTitle>
            {formData.missionId ? "Update Mission" : "Add Mission"}
          </DialogTitle>
          <DialogContent>
            <Question
              onQuestionsAdded={handleQuestionsAdded}
              questionType={formData.questionType}
              missionId={formData.missionId}
            />
            {inputFields.map((field) => (
              <TextField
                key={field.name}
                label={field.label}
                name={field.name}
                type={`${field.label.includes("Order")?"Number":"text"}`}
                value={formData[field.name] || ""}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
        
                disabled={field.disabled}
                error={field.name === "speed" && !isTimeValid(formData.speed)}
                helperText={
                  field.name === "speed" && !isTimeValid(formData.speed)
                    ? 'Invalid time format. Please use "hh:mm:ss".'
                    : ""
                }
              />
            ))}
            <FormControlLabel
              control={
                <Switch
                  checked={visibility}
                  onChange={handleVisibilityChange}
                  name="visibility"
                  color="primary"
                />
              }
              label="Visibility"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="error" variant="contained">
              Cancel
            </Button>
            <Button
              onClick={() => handleSave(skillId, stageId)}
              color="primary"
              variant="contained"
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <Battle />
    </div>
  );
};

export default Mission;
