import React, { useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Login from './auth/login/Login';
import ManageEmployee from './manage-employee/ManageEmployee';
import Header from './header/Header';
import Dashboard from './dashboard/Dashboard';
import './App.css';
import Sidebar from './sidenav/Sidenav';
import Roles from './roles/Roles';
import Specialisation from './specialisation/SpecializationMobile';
import Skills from './skills/Skills';
import { useNavigate } from 'react-router-dom';
import Stages from './stage/Stages';
import Mission from './mission/Mission';
import Profile from './profile/Profile';
import Experiment from './experiment/experiment';
import Labs from './lab/lab';
import Subscriptions from './subscription/subscriptions'
import Inquiries from './inquiries/Inquiries'
import ExperimentReport from './experimentReport/ExperimentReport';
import BattleReport from './battle-report/BattleReport';
import WarReport from './war-report/WarReport';
import SkillReport from './skill-report/SkillReport';
import UserCart from './cart/UserCart';
import BulkLogin from './bulkLogin/BulkLogin';
import SummerCamp from './summerCamp/summerCamp';
import QrCode from './qrCode/QRCode';
import Institute from './Institute/Institute';
import ReactGA from 'react-ga4'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ManualLoader from './Components/ManualLoader/ManualLoader';
import FinanceTab from './finance-tab/financeTab';
import ExperimentKey from './ExperimentKey/ExperimentKey';
import UserExperimentReport from './user-experiment-report/UserExperimentReport'
import Report from './experimentReport/Report';
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import ReportTab from './report-tab/ReportTab';
import { useLocalStorage } from '@uidotdev/usehooks';
import BlogsCategories from './Blogs/BlogCategories';
import Blogs from './Blogs/Blogs';
import Testimonials from './Testimonials/testimonials';
import isDesktop from './isDesktop';
import ExpKeyMobile from './ExperimentKey/ExpKeyMobile';
const TRACKING_ID = "G-Q1BG1GNPE9";
ReactGA.initialize(TRACKING_ID);

const withAuth = (Component) => {
  return (props) => {
    const [token,setToken] = useLocalStorage('token',null);
    // console.log(token);
    
    useEffect(() => {
      if(token && token!=="null"){
        // console.log(token);
        
        const decodedJwt=jwtDecode(token)
        // console.log(decodedJwt);
        const currentDate=new Date();
        // console.log(decodedJwt.exp,currentDate.getTime());
        if (decodedJwt.exp * 1000 < currentDate.getTime()) {
          setToken(null);
          localStorage.removeItem('token')
        } 
      }
      if (!token) {
        window.location.href = '/';
      }
    }, [token]);

    return token ? 
    <ManualLoader>
    <Component {...props} />
    </ManualLoader> : null;
  };
};

const AuthenticatedManageEmployee = withAuth(ManageEmployee);
const AuthenticatedInstitute = withAuth(Institute);
const AuthenticatedDashboard = withAuth(Dashboard);
const AuthenticatedRoles = withAuth(Roles);
const AuthenticatedSpecialisation = withAuth(Specialisation);
const AuthenticatedSkills = withAuth(Skills);
const AuthenticatedStages = withAuth(Stages);
const AuthenticatedMission = withAuth(Mission);
const AuthenticatedProfile = withAuth(Profile);
const AuthenticatedExperiment = withAuth(Experiment);
const AuthenticatedLab = withAuth(Labs);
const AuthenticatedSubscriptions = withAuth(Subscriptions);
const AuthenticatedInquiries = withAuth(Inquiries);
const AuthenticatedExperimentReport = withAuth(ExperimentReport);
const AuthenticatedBattleReport = withAuth(BattleReport);
const AuthenticatedWarReport = withAuth(WarReport);
const AuthenticatedSkillReport = withAuth(SkillReport);
const AuthenticatedUserCart = withAuth(UserCart);
const AuthenticatedBulkLogin = withAuth(BulkLogin);
const AuthenticatedSummerCamp = withAuth(SummerCamp);
const AuthenticatedQrCode = withAuth(QrCode);
const AuthenticatedFinanceTab = withAuth(FinanceTab);
const AuthenticatedExpKey = withAuth(ExpKeyMobile);
const AuthenticatedExpReportKey = withAuth(UserExperimentReport);
const AuthenticatedReportTab = withAuth(ReportTab);
const AuthenticatedTestimonials = withAuth(Testimonials);

const App = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [logoutDialog,setLogoutDialog]=useState(false)
  const [isLoggedIn,setIsLoggedIn]=useState(false);
  // const [token,setToken]=useLocalStorage("token",null)
  // const token1=localStorage.getItem("token")
  const [token,setToken]=useLocalStorage("token",null)
  
    useEffect(() => {
      // console.log(token);
      
      if(token){
        
        const decodedJwt=jwtDecode(token)
        // console.log(decodedJwt);
        const currentDate=new Date();
        // console.log(decodedJwt.exp,currentDate.getTime());
        if (decodedJwt.exp * 1000 < currentDate.getTime()) {
          setToken(null);

          localStorage.removeItem("token");
          setIsLoggedIn(false);
          return;
        } 
      }
      if (!token) {
        setIsLoggedIn(false);
        console.log("Hello");
        return;
      }
      setIsLoggedIn(true)
    }, [token]);


  // console.log(isLoggedIn);
  
  const navigate=useNavigate()
  const openSidebar = () => {
    setIsSidebarOpen(true);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  const handleLogout=()=>{
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    localStorage.removeItem('userRole');
    setIsSidebarOpen(false)
    setLogoutDialog(false)
    navigate('/');
  }
  const isDesktopDevice = isDesktop();
  return (
    <>
      <div style={{height:"95%"}}>
        {isDesktopDevice && <Header onMenuClick={openSidebar} isLoggedIn={isLoggedIn} />}
        {token && token!==null && <Sidebar isOpen={isSidebarOpen} onClose={closeSidebar} setLogoutDialog={setLogoutDialog} />}
       {isLoggedIn && <Sidebar isOpen={isSidebarOpen} onClose={closeSidebar} />}
        <div className="content-container" style={{height:"86%"}} >
          <Routes>
          <Route
            path="/labs/:userId/experiments/report/:attemptId"
            element={ 
                <Report />
            }
          />
            <Route path="" element={<Login setIsLoggedIn={setIsLoggedIn} />} />
            <Route path="/employee" element={<AuthenticatedManageEmployee />} />
            <Route path="/dashboard" element={<AuthenticatedDashboard />} />
            <Route path="/roles" element={<AuthenticatedRoles />} />
            <Route path="/specialization" element={<AuthenticatedSpecialisation />} />
            <Route path="/skills" element={<AuthenticatedSkills />} />
            <Route path="/skills/:skillId/stage" element={<AuthenticatedStages />} />
            <Route path="/skills/:skillId/stage/:stageId/mission" element={<AuthenticatedMission />} />
            <Route path="/profile" element={<AuthenticatedProfile />} />
            <Route path="/labs/:labId/experiments" element={<AuthenticatedExperiment />} />
            <Route path="/labs" element={<AuthenticatedLab />} />
            <Route path="/Subscriptions" element={<AuthenticatedSubscriptions />} />
            <Route path="/inquiries" element={<AuthenticatedInquiries />} />
            <Route path="/experiment-report" element={<AuthenticatedExperimentReport />} />
            <Route path="/battle-report" element={<AuthenticatedBattleReport />} />
            <Route path="/war-report" element={<AuthenticatedWarReport />} />
            <Route path="/skills-report" element={<AuthenticatedSkillReport />} />
            <Route path="/user-cart" element={<AuthenticatedUserCart />} />
            <Route path="/bulk-login" element={<AuthenticatedBulkLogin />} />
            <Route path="/summer-referrals" element={<AuthenticatedSummerCamp />} />
            <Route path="/qr-code" element={<AuthenticatedQrCode />} />       
            <Route path="/finance-tab" element={<AuthenticatedFinanceTab />} />
            <Route path="/institute" element={<AuthenticatedInstitute />} />
            <Route path="/expKey" element={<AuthenticatedExpKey />} />
            <Route path="/user-exp-report" element={<AuthenticatedExpReportKey />} />
            <Route path="/report" element={<ReportTab />} />
            <Route path="/blogs" element={<BlogsCategories />} />
            <Route path="/blogs/details/:catId" element={<Blogs />} />
            <Route path="/testimonials" element={<Testimonials />} />
          </Routes>
          <ToastContainer/>
        </div>
      </div>
    <Dialog open={logoutDialog} >
            <DialogTitle>Are you sure you want to Logout</DialogTitle>
            <DialogActions>
              <Button variant='contained' color='error' onClick={handleLogout} >Yes</Button>
              <Button variant='contained' onClick={()=>{setLogoutDialog(false)}} >No</Button>
            </DialogActions>
    </Dialog>
    </>
  );
};

export default App;
